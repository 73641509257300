import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"

const domId = "appRoot"
function render() {
  ReactDOM.render(<App />, document.getElementById(domId))
}
render({})
if (module.hot) {
  module.hot.accept()
}
