import React, { Suspense, lazy, useEffect, useRef } from "react"
import { HashRouter as Router, Route, Switch } from "react-router-dom"
import { Spin, ConfigProvider } from "antd"
import zhCN from "antd/es/locale-provider/zh_CN"
import "./App.scss"
const Home = lazy(() => import("./routes/Home"))

function App() {
  // 创建对<head>的引用
  const headRef = useRef(document.head); // 直接引用document.head

  useEffect(() => {
    // 创建Bing验证<meta>标签
    const bingMetaTag = document.createElement('meta');
    bingMetaTag.name = 'msvalidate.01';
    bingMetaTag.content = 'B52850531B6D4707A5FB7E096831BA2F';
    const baiduMetaTag = document.createElement('meta');
    baiduMetaTag.name = 'baidu-site-verification';
    baiduMetaTag.content = 'codeva-A5iuzFaVUE'; // 替换为您的百度验证代码

    // 将<meta>标签插入<head>
    headRef.current.appendChild(bingMetaTag);
    headRef.current.appendChild(baiduMetaTag);

    // 组件卸载时的清理工作
    return () => {
      headRef.current.removeChild(bingMetaTag);
      headRef.current.removeChild(baiduMetaTag);
    };
  }, []);

  // 由于headRef已经直接引用document.head，不需要再次设置

  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <Suspense fallback={<Spin />}>
          <div className="pg-wrap flex-column">
            <div className="pg-body">
              <div className="pg-body-inner">
                <Switch>
                  <Route exact path={"/"} component={Home} />
                </Switch>
              </div>
            </div>
          </div>
        </Suspense>
      </Router>
    </ConfigProvider>
  )
}

export default App;